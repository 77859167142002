import React, { useContext, useEffect, useState } from 'react'
import Header from '../Comman/Header'
import Sidebar from '../Comman/Sidebar'
import Footer from '../Comman/Footer'
import { AdminContext } from '../../AdminContext';
import axios from 'axios';
import Copy from "../Comman/Copy";
import { Link } from 'react-router-dom';
import Pagination from '../Comman/Pagination';


export default function SponserReward({ address }) {
    const { account, copyaddress, formatAddress } = useContext(AdminContext)
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [pages, setPages] = useState(1);
    const [data, setdata] = useState([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");

    const getData = async () => {
        try {
            setLoading(true);
            await axios
                .post(process.env.REACT_APP_BACKEND_LINK, {
                    method: "level",
                    submethod: "getbyid",
                    key: process.env.REACT_APP_KEY,
                    address,
                    search,
                    page: currentPage,
                    pageSize: pageSize,
                })
                .then((res) => {
                    setLoading(false);
                    if (res.data.error) {
                        return "";
                    }
                    setdata(res.data.data);
                    setPages(Math.ceil(res.data.dataLength / pageSize));
                });
        } catch (err) {
            console.log("error : ", err);
            setLoading(false);
        }
    };
    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    };
    // console.log('data', data)
    useEffect(() => {
        if (address) {
            getData();
        }
    }, [address, pages, pageSize, currentPage]);

    useEffect(() => {
        if (search) {
            setCurrentPage(1);
        }
        getData();
    }, [search]);
    return (
        <>
            <div class="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <h3>Sponser Reward</h3>
                        <div
                                className="position-relative"
                                style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
                            >
                                <input
                                    type="text"
                                    value={search}
                                    onChange={handleSearchChange}
                                    className="form-control"
                                    placeholder="Search..."
                                    autoComplete="off"
                                    id="search-options"
                                    style={{ width: "30%" }}
                                />
                                <span className="mdi mdi-magnify search-widget-icon" />
                                <span
                                    className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
                                    id="search-close-options"
                                />
                            </div>
                        </div>
                        <div className="table-responsive signal-table p-3">
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>From</th>
                                        <th>Hash</th>
                                        <th>Staking</th>
                                        <th>Level</th>
                                        <th>Income</th>
                                        <th>Income PER</th>
                                        <th>Datetime</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data && data?.length
                                        ? data.map((e, i) => {
                                            let ind = currentPage * pageSize - pageSize + (i + 1);
                                            return (
                                                <tr>
                                                    <td >
                                                        {ind}
                                                    </td>
                                                    <td>
                                                        <a
                                                            target="_blank"
                                                            className="text-primary"
                                                            href={`${process.env.REACT_APP_EXPLORER}/tx/${e.from}`}
                                                            rel="noreferrer"
                                                        >
                                                            {formatAddress(e.from)}
                                                        </a>
                                                        {e.from ? <Copy data={e.from} /> : ""}
                                                    </td>
                                                    <td>
                                                        <a
                                                            target="_blank"
                                                            className="text-primary"
                                                            href={`${process.env.REACT_APP_EXPLORER}/tx/${e.hash}`}
                                                            rel="noreferrer"
                                                        >
                                                            {formatAddress(e.hash)}
                                                        </a>
                                                        {e.hash ? <Copy data={e.hash} /> : ""}
                                                    </td>
                                                    <td>
                                                        <span>${e.staking ? e.staking : "0"}</span>
                                                    </td>
                                                    <td>
                                                        <span>{e.level ? e.level : "0"}</span>
                                                    </td>
                                                    <td>
                                                        <span>{e.income ? e.income : "0"}</span>
                                                    </td>
                                                    <td>
                                                        <span>({e.income_per ? e.income_per : "0"}%)</span>
                                                    </td>
                                                    <td >
                                                        {e.createdAt}
                                                    </td>
                                                    <td>
                                                        {e.status === true ? (
                                                            <div className="text-success">
                                                                Active
                                                            </div>
                                                        ) : (
                                                            <div className="text-danger">
                                                                Non Active
                                                            </div>
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })
                                        : ""}
                                </tbody>
                            </table>
                            {loading ? (
                                <div className="text-center">
                                    <p>Loading...</p>
                                </div>
                            ) : !data || !data.length ? (
                                <div className="text-center">
                                    <p>No data found.</p>
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                        <div className='d-flex justify-content-center py-4'>
                            <Pagination
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                pageSize={pageSize}
                                setPageSize={setPageSize}
                                pages={pages}
                            />
                            {/* <nav aria-label="Page navigation example">
                                                <ul className="pagination">
                                                    <li className="page-item"><a className="page-link" href="#">Previous</a></li>
                                                    <li className="page-item"><a className="page-link" href="#">1</a></li>
                                                    <li className="page-item"><a className="page-link" href="#">2</a></li>
                                                    <li className="page-item"><a className="page-link" href="#">3</a></li>
                                                    <li className="page-item"><a className="page-link" href="#">Next</a></li>
                                                </ul>
                                            </nav> */}

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


// import React, { useContext, useEffect, useState } from 'react';
// import Header from '../Comman/Header';
// import Sidebar from '../Comman/Sidebar';
// import Footer from '../Comman/Footer';
// import { AdminContext } from '../../AdminContext';
// import axios from 'axios';
// import Copy from '../Comman/Copy';
// import Pagination from '../Comman/Pagination';

// export default function PendingWithdrawalSidebar() {
//     const { formatAddress, toastSuccess, toastError } = useContext(AdminContext);
//     const [currentPage, setCurrentPage] = useState(1);
//     const [pageSize, setPageSize] = useState(10);
//     const [pages, setPages] = useState(1);
//     const [data, setData] = useState([]);
//     const [loading, setLoading] = useState(false);
//     const [search, setSearch] = useState("");
//     const [fromDate, setFromDate] = useState(null);
//     const [toDate, setToDate] = useState(null);
//     const [authNumber, setAuthNumber] = useState(''); // Added authNumber state
//     const [error, setError] = useState(false);
//     const [errorMessage, setErrorMessage] = useState('');
//     const [msg, setMsg] = useState('');
//     const [showMsg, setShowMsg] = useState(false);

//     // Insert Code Handler
//     const insertCode = async () => {
//         try {
//             const state = JSON.parse(localStorage.getItem('mark#0143'));
//             const response = await axios.post(process.env.REACT_APP_BACKEND_LINK, {
//                 method: "verify",
//                 submethod: "insert",
//                 key: process.env.REACT_APP_KEY,
//                 authNumber,
//                 username: state.username,
//                 _id: state._id,
//             });

//             const { resCode, error, token } = response.data;

//             if (resCode === 200) {
//                 if (token) {
//                     setMsg("Verified successfully");
//                     setShowMsg(true);
//                 } else {
//                     setErrorMessage("Unable to generate token.");
//                     setError(true);
//                 }
//             } else {
//                 setErrorMessage(error || "Verification failed. Please try again.");
//                 setError(true);
//             }
//         } catch (err) {
//             console.error("Error inserting data:", err);
//             setErrorMessage("Network error. Please try again later.");
//             setError(true);
//         }
//     };

//     // Fetch Data Handler
//     const getData = async () => {
//         try {
//             setLoading(true);
//             const res = await axios.post(process.env.REACT_APP_BACKEND_LINK, {
//                 method: "transaction",
//                 submethod: "get",
//                 key: process.env.REACT_APP_KEY,
//                 search,
//                 fromdate: fromDate,
//                 todate: toDate,
//                 page: currentPage,
//                 pageSize,
//             });

//             setLoading(false);
//             if (res.data.error) {
//                 toastError("Failed to fetch data");
//                 setData([]);
//                 return;
//             }

//             setData(res.data.data);
//             setPages(Math.ceil(res.data.dataLength / pageSize));
//         } catch (err) {
//             console.log("Error fetching data:", err);
//             toastError("An error occurred while fetching data");
//             setLoading(false);
//         }
//     };

//     // Effect Hook for Data Fetching
//     useEffect(() => {
//         if (search || fromDate || toDate) {
//             setCurrentPage(1); // Reset to page 1 when searching or filtering
//         }
//         getData();
//     }, [currentPage, search, fromDate, toDate, pageSize]);

//     return (
//         <>
//             <main className="page-wrapper compact-wrapper" id="pageWrapper">
//                 <Header />
//                 <div className="page-body-wrapper">
//                     <Sidebar />
//                     <div className="page-body">
//                         <div className="container-fluid">
//                             <div className="row page-title">
//                                 <div className="col-sm-6">
//                                     <div className="auth-form">
//                                         <div className="form-group">
//                                             <label className="form-label">Authentication Number</label>
//                                             <input
//                                                 type="text"
//                                                 className="form-control"
//                                                 onChange={(e) => setAuthNumber(e.target.value)}
//                                             />
//                                         </div>
//                                         {error && errorMessage && (
//                                             <div className="alert alert-danger" role="alert">
//                                                 {errorMessage}
//                                             </div>
//                                         )}
//                                         {showMsg && msg && (
//                                             <div className="alert alert-success" role="alert">
//                                                 {msg}
//                                             </div>
//                                         )}
//                                         <div className="text-center">
//                                             <button
//                                                 type="button"
//                                                 className="btn btn-primary btn-block"
//                                                 onClick={insertCode}
//                                             >
//                                                 Log In
//                                             </button>
//                                         </div>
//                                     </div>
//                                     <h3>Pending Withdrawal</h3>
//                                 </div>
//                             </div>
//                         </div>

//                         {/* Pending Withdrawal Table */}
//                         <div className="container-fluid">
//                             <div className="row">
//                                 <div className="col-sm-12">
//                                     <div className="card">
//                                         <div className="table-responsive signal-table p-3">
//                                             <table className="table table-hover">
//                                                 <thead>
//                                                     <tr>
//                                                         <th>#</th>
//                                                         <th>Hash</th>
//                                                         <th>Address</th>
//                                                         <th>Amount</th>
//                                                         <th>Bot</th>
//                                                         <th>DateTime</th>
//                                                     </tr>
//                                                 </thead>
//                                                 <tbody>
//                                                     {data.length > 0 ? (
//                                                         data.map((ele, i) => {
//                                                             const index = currentPage * pageSize - pageSize + (i + 1);
//                                                             return (
//                                                                 <tr key={ele.address}>
//                                                                     <td>
//                                                                         <span className="d-flex justify-content-center">{index}</span>
//                                                                     </td>
//                                                                     <td>
//                                                                         <a
//                                                                             target="_blank"
//                                                                             className="text-primary"
//                                                                             href={`${process.env.REACT_APP_EXPLORER}/tx/${ele.hash}`}
//                                                                             rel="noreferrer"
//                                                                         >
//                                                                             {formatAddress(ele.hash)}
//                                                                         </a>
//                                                                         {ele.hash ? <Copy data={ele.hash} /> : ""}
//                                                                     </td>
//                                                                     <td>
//                                                                         <a
//                                                                             target="_blank"
//                                                                             className="text-primary"
//                                                                             href={`${process.env.REACT_APP_EXPLORER}/tx/${ele.address}`}
//                                                                             rel="noreferrer"
//                                                                         >
//                                                                             {formatAddress(ele.address)}
//                                                                         </a>
//                                                                         {ele.address ? <Copy data={ele.address} /> : ""}
//                                                                     </td>
//                                                                     <td><span>${ele.amount ? ele.amount : "0"}</span></td>
//                                                                     <td>
//                                                                         <span>
//                                                                             {ele.amount === 25 ? "BEGINNER BOT" :
//                                                                              ele.amount === 100 ? "INTERMEDIATE BOT" :
//                                                                              ele.amount === 500 ? "PROFESSIONAL BOT" : "Invalid Bot"}
//                                                                         </span>
//                                                                     </td>
//                                                                     <td>{ele.datetime}</td>
//                                                                 </tr>
//                                                             );
//                                                         })
//                                                     ) : loading ? (
//                                                         <tr>
//                                                             <td colSpan="6" className="text-center">
//                                                                 <p>Loading...</p>
//                                                             </td>
//                                                         </tr>
//                                                     ) : (
//                                                         <tr>
//                                                             <td colSpan="6" className="text-center">
//                                                                 <p>No data found.</p>
//                                                             </td>
//                                                         </tr>
//                                                     )}
//                                                 </tbody>
//                                             </table>
//                                         </div>

//                                         {/* Pagination Component */}
//                                         <div className="d-flex justify-content-center py-4">
//                                             <Pagination
//                                                 currentPage={currentPage}
//                                                 setCurrentPage={setCurrentPage}
//                                                 pageSize={pageSize}
//                                                 setPageSize={setPageSize}
//                                                 pages={pages}
//                                             />
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <Footer />
//                 </div>
//             </main>
//         </>
//     );
// }
