import React, { createContext, useEffect, useState } from "react";
import App from "./App";
import axios from "axios";
import copy from "copy-to-clipboard";
import Web3 from "web3";
import toast, { Toaster } from "react-hot-toast";


export const AdminContext = createContext();

const web3 = new Web3(process.env.REACT_APP_RPC);
function Admincontext() {
  const [sidebar, setSidebar] = useState(false);
  const [settings, setsettings] = useState([]);
  const [dashd, setdashd] = useState([]);

  const formatAddress = (address) => {
    if (address) {
      return address.substr(0, 6) + "..." + address.substr(-6, 6);
    }
  };
  const copyText = (data) => {
    copy(data);
  };

  const getSetting = async () => {
    // console.log("link :- ", process.env.REACT_APP_BACKEND_LINK, process.env.REACT_APP_KEY);
    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "setting",
        submethod: "get",
        key: process.env.REACT_APP_KEY,
      })
      .then((res) => {
        // console.log("res", res.data.data);
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          return "";
        }
        setsettings(res.data.data);
      });
  };
  const getDashData = async () => {
    // console.log("link :- ", process.env.REACT_APP_BACKEND_LINK, process.env.REACT_APP_KEY);
    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "user",
        submethod: "dashd",
        key: process.env.REACT_APP_KEY,
      })
      .then((res) => {
        // console.log("resdasdas", res.data);
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          return "";
        }
        setdashd(res.data.data);
      });
  };

  const toastError = (data) => toast.error(data);
  const toastSuccess = (data) => toast.success(data);
  const toastInfo = (data) =>
    toast(data, {
      icon: "❕",
      ariaProps: {
        role: "status",
        "aria-live": "polite",
      },
    });

  const getudata = async (address) => {
    return await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, {
      method: "user",
      submethod: "getbyid",
      key: process.env.REACT_APP_KEY,
      address: address ? address.toLowerCase() : address,
    });
  };

  useEffect(() => {
    getSetting();
    getDashData();
  }, []);

  function formatDateTime(date) {
    const datetime = new Date(date);
    const day = String(datetime.getDate()).padStart(2, "0");
    const month = String(datetime.getMonth() + 1).padStart(2, "0");
    const year = datetime.getFullYear();
    const hours = String(datetime.getHours()).padStart(2, "0");
    const minutes = String(datetime.getMinutes()).padStart(2, "0");
    const seconds = String(datetime.getSeconds()).padStart(2, "0");

    const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    return formattedDate;
  }


  return (
    <>
      <AdminContext.Provider
        value={{
          web3,
          settings: settings,
          getudata,
          copyText,
          formatDateTime,
          formatAddress,
          dashd,
          sidebar, setSidebar,
          toastError,
          toastSuccess,

        }}
      >
        <App />
      </AdminContext.Provider>
    </>
  );
}

export default Admincontext;
