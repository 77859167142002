import React, { useState } from 'react';
import Header from '../Comman/Header';
import Sidebar from '../Comman/Sidebar';
import Footer from '../Comman/Footer';
import axios from 'axios';

export default function Liquidity() {
    // State variables to hold form inputs
    const [address, setAddress] = useState('');
    const [amount, setAmount] = useState(0);
    const [trnote, settrnote] = useState(null);
    const [loading, setLoading] = useState(false);  // Loading state for the form submission
    const [error, seterror] = useState('');



    const onStake = async () => {

        await axios
            .post(process.env.REACT_APP_BACKEND_LINK, {
                method: "staking",
                submethod: "insertadmin",
                address: address,
                amount: amount,
                note:trnote,
                key: process.env.REACT_APP_KEY
            })
            .then((res) => {
                // var dedata = decryptData(res.data);
                if (res.data.error) {
                    seterror(res.data.error)
                    return "";
                }
                seterror(res.data.msg)
            });
    };


    return (
        <>
            <main className="page-wrapper compact-wrapper" id="pageWrapper">
                <Header />
                <div className="page-body-wrapper">
                    <Sidebar />
                    <div className="page-body">
                        <div className="container-fluid ecommerce-dashboard">
                            <div className="row">
                                <div className="col-12 p-0">
                                    <div className="login-card login-dark">
                                        <div className="login-main">
                                            <div className="theme-form" >
                                                <h2 className="text-center">Deposit</h2>
                                                <div className="form-group">
                                                    <label className="col-form-label">Address</label>
                                                    <input
                                                        className="form-control"
                                                        required
                                                        placeholder="Address"
                                                        value={address}
                                                        onChange={(e) => setAddress(e.target.value)}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-form-label">Enter Amount</label>
                                                    <input
                                                        className="form-control"
                                                        required
                                                        placeholder="Enter Amount"
                                                        value={amount}
                                                        onChange={(e) => setAmount(e.target.value)}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-form-label">Enter Note</label>
                                                    <textarea 
                                                        className="form-control"
                                                        placeholder="Enter Note"
                                                        value={trnote}
                                                        onChange={(e) => settrnote(e.target.value)}
                                                    />
                                                </div>
                                                <h5>USDT...</h5>
                                                {/* <div>
                                                    <input
                                                        type="radio"
                                                        id="ton"
                                                        name="tokens"
                                                        checked={address === 'ton'}
                                                        onChange={() => setAddress("ton")}
                                                    />
                                                    &nbsp;
                                                    <label htmlFor="ton">Ton (BEP20) </label>
                                                    <br />
                                                </div>
                                                <div>
                                                    <input
                                                        type="radio"
                                                        id="css"
                                                        name="tokens"
                                                        checked={address === 'usdt'}
                                                        onChange={() => setAddress("usdt")}
                                                    />
                                                    &nbsp; <label htmlFor="css">USDT</label>
                                                    <br />
                                                </div> */}
                                                {/* <div className="form-group">
                                                    <label className="col-form-label">Amount ($25,$100,$500)</label>
                                                    <div className="form-input position-relative">
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            required
                                                            placeholder="Amount"
                                                            value={amount}
                                                            onChange={(e) => setAmount(e.target.value)}
                                                        />
                                                    </div>
                                                </div> */}
                                                <div className='text-warning'>
                                                    {error}
                                                </div>

                                                <div className="form-group mb-0 checkbox-checked mb-5">
                                                    <div className="text-end mt-3">
                                                        <button type="submit" className="btn btn-primary btn-block w-100 text-white" disabled={loading} onClick={() => onStake()}>
                                                        Add Liquidity
                                                            
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </main>
        </>
    );
}
