import React, { useContext, useEffect, useState } from 'react';
import Header from '../Comman/Header';
import Sidebar from '../Comman/Sidebar';
import Footer from '../Comman/Footer';
import { AdminContext } from '../../AdminContext';
import axios from 'axios';
import Copy from "../Comman/Copy";
import { Link } from 'react-router-dom';
import Pagination from '../Comman/Pagination';

export default function SponserRewardSidbar() {
    const { formatAddress, toastSuccess, toastError } = useContext(AdminContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [pages, setPages] = useState(1);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);

    const getData = async () => {
        console.log('search', search)
        try {
            setLoading(true);
            const res = await axios.post(process.env.REACT_APP_BACKEND_LINK, {
                method: "level",
                submethod: "get",
                key: process.env.REACT_APP_KEY,
                search,
                fromdate: fromDate,
                todate: toDate,
                page: currentPage,
                pageSize,
            });

            // console.log("user data1121", res.data.data);
            setLoading(false);

            if (res.data.error) {
                toastError("Failed to fetch data"); // Improved error handling
                setData([]);
                setPages(0);
                return;
            }

            setData(res.data.data);
            setPages(Math.ceil(res.data.dataLength / pageSize));
        } catch (err) {
            console.log("error: ", err);
            toastError("An error occurred while fetching data"); // Improved error handling
            setLoading(false);
        }
    };
    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    };
    useEffect(() => {
        if (search || fromDate || toDate) {
            setCurrentPage(1); // Reset to page 1 when searching or filtering
        }
        getData();
    }, [currentPage, search, fromDate, toDate, pageSize]); // Updated dependencies

    return (
        <>
            <main className="page-wrapper compact-wrapper" id="pageWrapper">
                <Header />
                <div className="page-body-wrapper">
                    <Sidebar />
                    <div className="page-body">
                        <div className="container-fluid">
                            <div className="row page-title">
                                <div className="col-sm-6">
                                    <h3>Sponser Reward</h3>
                                </div>
                            </div>
                        </div>
                        {/* Container-fluid starts */}
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card">
                                        <div className="card-header"></div>
                                        <div className="table-responsive signal-table p-3">
                                                    <div className="position-relative">
                                                        <input type="text" value={search} onChange={handleSearchChange} style={{ width: "30%" }} className="form-control" placeholder="Search..." autoComplete="off" id="search-options" />
                                                        <span className="mdi mdi-magnify search-widget-icon" />
                                                        <span className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none" id="search-close-options" />
                                                    </div>
                                            <table className="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>From</th>
                                                        <th>Hash</th>
                                                        <th>Staking</th>
                                                        <th>Level</th>
                                                        <th>Income</th>
                                                        <th>Income PER</th>
                                                        <th>Datetime</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {data && data?.length
                                                        ? data.map((e, i) => {
                                                            let ind = currentPage * pageSize - pageSize + (i + 1);
                                                            return (
                                                                <tr>
                                                                    <td >
                                                                        {ind}
                                                                    </td>
                                                                    <td>
                                                                        <a
                                                                            target="_blank"
                                                                            className="text-primary"
                                                                            href={`${process.env.REACT_APP_EXPLORER}/tx/${e.from}`}
                                                                            rel="noreferrer"
                                                                        >
                                                                            {formatAddress(e.from)}
                                                                        </a>
                                                                        {e.from ? <Copy data={e.from} /> : ""}
                                                                    </td>
                                                                    <td>
                                                                        <a
                                                                            target="_blank"
                                                                            className="text-primary"
                                                                            href={`${process.env.REACT_APP_EXPLORER}/tx/${e.hash}`}
                                                                            rel="noreferrer"
                                                                        >
                                                                            {formatAddress(e.hash)}
                                                                        </a>
                                                                        {e.hash ? <Copy data={e.hash} /> : ""}
                                                                    </td>
                                                                    <td>
                                                                        <span>${e.staking ? e.staking : "0"}</span>
                                                                    </td>
                                                                    <td>
                                                                        <span>{e.level ? e.level : "0"}</span>
                                                                    </td>
                                                                    <td>
                                                                        <span>{e.income ? e.income : "0"}</span>
                                                                    </td>
                                                                    <td>
                                                                        <span>({e.income_per ? e.income_per : "0"}%)</span>
                                                                    </td>
                                                                    <td >
                                                                        {e.datetime}
                                                                    </td>
                                                                    <td>
                                                                        {e.status === true ? (
                                                                            <div className="text-success">
                                                                                Active
                                                                            </div>
                                                                        ) : (
                                                                            <div className="text-danger">
                                                                                Non Active
                                                                            </div>
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                        : ""}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className='d-flex justify-content-center py-4'>
                                            <Pagination
                                                currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}
                                                pageSize={pageSize}
                                                setPageSize={setPageSize}
                                                pages={pages}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </main>
        </>
    );
}
