import React, { useContext, useEffect, useState } from 'react'
import Header from '../Comman/Header'
import Sidebar from '../Comman/Sidebar'
import Footer from '../Comman/Footer'
import { AdminContext } from '../../AdminContext';
import axios from 'axios';
import Copy from "../Comman/Copy";
import { Link } from 'react-router-dom';
import Pagination from '../Comman/Pagination';


export default function TradeFundMobilisationReport({ address }) {
    const { account, copyaddress, formatAddress } = useContext(AdminContext)
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [pages, setPages] = useState(1);
    const [data, setdata] = useState([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");

    const getData = async (address) => {
        try {
            setLoading(true);
            await axios
                .post(process.env.REACT_APP_BACKEND_LINK, {
                    method: "tfm",
                    submethod: "getbyid",
                    key: process.env.REACT_APP_KEY,
                    address,
                    search,
                    page: currentPage,
                    pageSize: pageSize,
                })
                .then((res) => {
                    setLoading(false);
                    if (res.data.error) {
                        return "";
                    }
                    setdata(res.data.data);
                    setPages(Math.ceil(res.data.dataLength / pageSize));
                });
        } catch (err) {
            console.log("error : ", err);
            setLoading(false);
        }
    };
    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    };
    // console.log('data', data)
    useEffect(() => {
        if (address) {
            getData(address);
        }
    }, [address, pages, pageSize, currentPage]);

    useEffect(() => {
        if (search) {
            setCurrentPage(1);
        }
        getData();
    }, [search]);
    return (
        <>
            <div class="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <h3>Trade Fund Mobilisation</h3>
                        <div
                                className="position-relative"
                                style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
                            >
                                <input
                                    type="text"
                                    value={search}
                                    onChange={handleSearchChange}
                                    className="form-control"
                                    placeholder="Search..."
                                    autoComplete="off"
                                    id="search-options"
                                    style={{ width: "30%" }}
                                />
                                <span className="mdi mdi-magnify search-widget-icon" />
                                <span
                                    className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
                                    id="search-close-options"
                                />
                            </div>
                        </div>
                        <div className="table-responsive signal-table p-3">
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Total Staking</th>
                                        <th>Income</th>
                                        <th>Income Per</th>
                                        <th>Level</th>
                                        <th>Datetime</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data && data?.length
                                        ? data.map((e, i) => {
                                            let ind = currentPage * pageSize - pageSize + (i + 1);
                                            return (
                                                <tr>
                                                    <td >
                                                        {ind}
                                                    </td>                                        
                                                        <td>
                                                        <span>${e.t_staking ? e.t_staking : "0"}</span>
                                                        </td>
                                                        <td>
                                                        <span>${e.income ? e.income : "0"}</span>
                                                        </td>
                                                        <td>
                                                        <span>({e.income_per ? e.income_per : "0"}%)</span>
                                                        </td>
                                                        <td>
                                                        <span>{e.level ? e.level : "0"}</span>
                                                        </td>
                                                    <td >
                                                        {e.createdAt}
                                                    </td>
                                                </tr>
                                            );
                                        })
                                        : ""}
                                </tbody>
                            </table>
                            {loading ? (
                                <div className="text-center">
                                    <p>Loading...</p>
                                </div>
                            ) : !data || !data.length ? (
                                <div className="text-center">
                                    <p>No data found.</p>
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                        <div className='d-flex justify-content-center py-4'>
                            <Pagination
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                pageSize={pageSize}
                                setPageSize={setPageSize}
                                pages={pages}
                            />
                            {/* <nav aria-label="Page navigation example">
                                                <ul className="pagination">
                                                    <li className="page-item"><a className="page-link" href="#">Previous</a></li>
                                                    <li className="page-item"><a className="page-link" href="#">1</a></li>
                                                    <li className="page-item"><a className="page-link" href="#">2</a></li>
                                                    <li className="page-item"><a className="page-link" href="#">3</a></li>
                                                    <li className="page-item"><a className="page-link" href="#">Next</a></li>
                                                </ul>
                                            </nav> */}

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
