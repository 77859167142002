import React, { useContext, useEffect, useState } from 'react';
import Header from '../Comman/Header';
import Sidebar from '../Comman/Sidebar';
import Footer from '../Comman/Footer';
import { AdminContext } from '../../AdminContext';
import axios from 'axios';
import Copy from "../Comman/Copy";
import Pagination from '../Comman/Pagination';

export default function PendingSponserSidbar() {

    const { formatAddress } = useContext(AdminContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [pages, setPages] = useState(1);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pendloading, setpendLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [msg, setMsg] = useState('');
    const [showMsg, setShowMsg] = useState(false);
    const [authNumber, setAuthNumber] = useState(''); // Added authNumber state
    const [isAuthenticated, setIsAuthenticated] = useState(false); // New state to track authentication

    const insertCode = async () => {
        try {
            const state = JSON.parse(localStorage.getItem('mark#0143'));
            const response = await axios.post(process.env.REACT_APP_BACKEND_LINK, {
                method: "verify",
                submethod: "insert",
                key: process.env.REACT_APP_KEY,
                authNumber,
                username: state.username,
                _id: state._id,
            });

            const { resCode, error, token } = response.data;

            if (resCode === 200 && token) {
                setMsg("Verified successfully");
                setShowMsg(true);
                setIsAuthenticated(true);
            } else {
                setErrorMessage(error || "Verification failed. Please try again.");
                setError(true);
            }
        } catch (err) {
            console.error("Error inserting data:", err);
            setErrorMessage("Network error. Please try again later.");
            setError(true);
        }
    };


    const givePending = async () => {
        try {
            setpendLoading(true);
            const res = await axios.post(process.env.REACT_APP_BACKEND_LINK, {
                method: "level",
                submethod: "pool",
                key: process.env.REACT_APP_KEY,
            });

            setpendLoading(false);

            if (res.data.error) {
                console.error("Error: ", res.data.error); // Log the error from the backend
                alert(res.data.error)

                return;
            } else {
                alert(res.data.data)
                getData()
            }

        } catch (err) {
            console.log("error : ", err);
            setpendLoading(false);
        }
    };
    const getData = async () => {
        try {
            setLoading(true);
            const res = await axios.post(process.env.REACT_APP_BACKEND_LINK, {
                method: "level",
                submethod: "getpbyid",
                key: process.env.REACT_APP_KEY,
                search,
                page: currentPage,
                pageSize: pageSize,
            });

            setLoading(false);

            if (res.data.resCode !== 200) {
                console.error("Error: ", res.data.error); // Log the error from the backend
                setData([]); // Clear data if error occurs
                return;
            }
            // console.log("res.data.data", res.data.data);

            setData(res.data.data); // Set the actual data
            setPages(Math.ceil(res.data.dataLength / pageSize)); // Pagination logic
        } catch (err) {
            console.log("error : ", err);
            setLoading(false);
        }
    };


        
    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    };
    // console.log('data', data)
    useEffect(() => {
        if (search || fromDate || toDate) {
            setCurrentPage(1); // Reset to page 1 when searching or filtering
        }
        getData();
    }, [currentPage, search, fromDate, toDate, pageSize]); // Updated dependencies

    return (
        <>
            <main className="page-wrapper compact-wrapper" id="pageWrapper">
                <Header />
                <div className="page-body-wrapper">
                    <Sidebar />
                    <div className="page-body">
                        <div className="container-fluid">
                            <div className="row page-title">
                                <div className="col-s-10">
                                    {!isAuthenticated ? (
                                        // Authentication Form
                                        <div className="auth-form">
                                            <div className="form-group">
                                                <label className="form-label center">Authentication Number</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    onChange={(e) => setAuthNumber(e.target.value)}
                                                />
                                            </div>
                                            {error && errorMessage && (
                                                <div className="alert alert-danger" role="alert">
                                                    {errorMessage}
                                                </div>
                                            )}
                                            {showMsg && msg && (
                                                <div className="alert alert-success" role="alert">
                                                    {msg}
                                                </div>
                                            )}
                                            <div className="text-center">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary btn-block"
                                                    onClick={insertCode}
                                                >
                                                    Log In
                                                </button>
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            <h3>Pending Sponser Reward</h3>
                                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-warning waves-effect waves-light material-shadow-none"
                                                    onClick={() => givePending()}
                                                >
                                                    Give Pending Income
                                                </button>
                                            </div>

                                            <div className="container-fluid">
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="card">
                                                            <div className="table-responsive signal-table p-3">
                                                                <div className="position-relative">
                                                                    <input type="text" value={search} onChange={handleSearchChange} style={{ width: "30%" }} className="form-control" placeholder="Search..." autoComplete="off" id="search-options" />
                                                                    <span className="mdi mdi-magnify search-widget-icon" />
                                                                    <span className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none" id="search-close-options" />
                                                                </div>
                                                                <table className="table table-hover">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>#</th>
                                                                            <th>Address</th>
                                                                            <th>Income</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {data && data?.length
                                                                            ? data.map((e, i) => {
                                                                                let ind = currentPage * pageSize - pageSize + (i + 1);
                                                                                return (
                                                                                    <tr>
                                                                                        <td >
                                                                                            {ind}
                                                                                        </td>
                                                                                        <td>
                                                                                            <a
                                                                                                target="_blank"
                                                                                                className="text-primary"
                                                                                                href={`${process.env.REACT_APP_EXPLORER}/address/${e.address}`}
                                                                                                rel="noreferrer"
                                                                                            >
                                                                                                {formatAddress(e.address)}
                                                                                            </a>
                                                                                            {e.address ? <Copy data={e.address} /> : ""}
                                                                                        </td>
                                                                                        <td >
                                                                                            <span>${e.level_inc_pending ? e.level_inc_pending : "0"}</span>
                                                                                        </td>
                                                                                    </tr>
                                                                                );
                                                                            })
                                                                            : ""}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            {/* Pagination */}
                                                            <div className="d-flex justify-content-center py-4">
                                                                <Pagination
                                                                    currentPage={currentPage}
                                                                    setCurrentPage={setCurrentPage}
                                                                    pageSize={pageSize}
                                                                    setPageSize={setPageSize}
                                                                    pages={pages}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </main >
        </>
    );
}
