import React, { useContext, useEffect, useState } from 'react';
import Header from '../Comman/Header';
import Sidebar from '../Comman/Sidebar';
import Footer from '../Comman/Footer';
import { AdminContext } from '../../AdminContext';
import axios from 'axios';
import Copy from "../Comman/Copy";
import { Link } from 'react-router-dom';
import Pagination from '../Comman/Pagination';

export default function PurchaseHistorySidbar() {
    const { formatAddress, toastSuccess, toastError } = useContext(AdminContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [pages, setPages] = useState(1);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [adoption, setadoption] = useState('0');

    const getData = async () => {
        try {
            setLoading(true);
            const res = await axios.post(process.env.REACT_APP_BACKEND_LINK, {
                method: "transaction",
                submethod: "get",
                key: process.env.REACT_APP_KEY,
                search,
                adoption: adoption,
                fromdate: fromDate,
                todate: toDate,
                page: currentPage,
                pageSize,
            });

            // console.log("user data", res.data.data);
            setLoading(false);

            if (res.data.error) {
                toastError("Failed to fetch data"); // Improved error handling
                setData([]);
                return;
            }

            setData(res.data.data);
            setPages(Math.ceil(res.data.dataLength / pageSize));
        } catch (err) {
            console.log("error: ", err);
            toastError("An error occurred while fetching data"); // Improved error handling
            setLoading(false);
        }
    };
    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    };
    useEffect(() => {
        if (search || fromDate || toDate) {
            setCurrentPage(1); // Reset to page 1 when searching or filtering
        }
        getData();
    }, [currentPage, search, fromDate, toDate, pageSize, adoption]); // Updated dependencies

    return (
        <>
            <main className="page-wrapper compact-wrapper" id="pageWrapper">
                <Header />
                <div className="page-body-wrapper">
                    <Sidebar />
                    <div className="page-body">
                        <div className="container-fluid">
                            <div className="row page-title">
                                <div className="col-sm-6">
                                    <h3>Purchase History</h3>
                                </div>
                            </div>
                        </div>
                        {/* Container-fluid starts */}
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card">
                                        <div className='col-md-2 p-3'>
                                            <label for="mejf">Choose option:</label>
                                            <select name="mejf" id="mejf" className="form-select" onChange={(e) => setadoption(e.target.value)}>
                                                <option value="0">------All------</option>
                                                <option value="1">Admin</option>
                                                <option value="2">Direct</option>
                                            </select>
                                        </div>
                                        <div className="position-relative">
                                                <input type="text" value={search} onChange={handleSearchChange} style={{ width: "30%" }} className="form-control" placeholder="Search..." autoComplete="off" id="search-options" />
                                                <span className="mdi mdi-magnify search-widget-icon" />
                                                <span className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none" id="search-close-options" />
                                            </div>
                                        <div className="card-header"></div>
                                        <div className="table-responsive signal-table p-3">
                                            <table className="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Hash</th>
                                                        <th>Address</th>
                                                        <th>Amount</th>
                                                        <th>Bot</th>
                                                        <th>DateTime</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {data.length > 0 ? (
                                                        data.map((ele, i) => {
                                                            const index = currentPage * pageSize - pageSize + (i + 1);
                                                            return (
                                                                <tr key={ele.address}> {/* Added key prop */}
                                                                    <td>
                                                                        <span className="d-flex justify-content-center">
                                                                            {index}
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <a
                                                                            target="_blank"
                                                                            className="text-primary"
                                                                            href={`${process.env.REACT_APP_EXPLORER}/tx/${ele.hash}`}
                                                                            rel="noreferrer"
                                                                        >
                                                                            {formatAddress(ele.hash)}
                                                                        </a>
                                                                        {ele.hash ? <Copy data={ele.hash} /> : ""}
                                                                    </td>
                                                                    <td>
                                                                        <a
                                                                            target="_blank"
                                                                            className="text-primary"
                                                                            href={`${process.env.REACT_APP_EXPLORER}/tx/${ele.address}`}
                                                                            rel="noreferrer"
                                                                        >
                                                                            {formatAddress(ele.address)}
                                                                        </a>
                                                                        {ele.address ? <Copy data={ele.address} /> : ""}
                                                                    </td>
                                                                    <td>
                                                                        <span>${ele.amount ? ele.amount : "0"}</span>
                                                                    </td>
                                                                    <td>
                                                                        <span>{ele.amount === 25 ? "BEGINNER BOT" : ele.amount === 100 ? "INTERMEDIATE BOT" : ele.amount === 500 ? "PROFESSIONAL BOT" : "Invalid Bot"}</span>
                                                                    </td>
                                                                    <td>{ele.datetime}</td>
                                                                </tr>
                                                            );
                                                        })
                                                    ) : loading ? (
                                                        <tr>
                                                            <td colSpan="10" className="text-center">
                                                                <p>Loading...</p>
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="10" className="text-center">
                                                                <p>No data found.</p>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className='d-flex justify-content-center py-4'>
                                            <Pagination
                                                currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}
                                                pageSize={pageSize}
                                                setPageSize={setPageSize}
                                                pages={pages}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </main>
        </>
    );
}
